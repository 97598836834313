var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumbDetail" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.gobackDetail } },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "depoErrorDetail" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "left", model: _vm.formInline },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #C0CCDA",
                    "border-radius": "4px",
                    padding: "15px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _c("span", [_vm._v("数据传输记录详情")]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "padding-left": "20px" } },
                    [
                      _c("el-form-item", { attrs: { label: "规则名称:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.edrsRuleName)),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "规则标签:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.tag))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "状态:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.statusDesc))]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "最大重试次数:" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.retryCount)),
                          ]),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "业务数据:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.body))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "创建时间:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.createdTime)),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "返回结果:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.remark))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "更新时间:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.updatedTime)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }